import "element-plus/lib/components/message-box/style/css";
import _ElMessageBox from "element-plus/lib/components/message-box";
import "core-js/modules/es.array.concat.js";
import BackgroundVideo from '@/components/backgroundVideo.vue';
import industryInstructions from '@/components/industryInstructions.vue';
import industryHighlightText from '@/components/industryHighlightText.vue';
import mynFeatures from '@/components/mynFeatures.vue';
import '@/components/industry.css'; // import industryCard from '@/components/industryCard.vue';

export default {
  name: 'Oil',
  data: function data() {
    return {
      prestring: '<div class="additional"> <div class="user-card"> <small class="stats" style:"color:white; font-size:0.25rem> Oil</small></div> <div class="more-info"> <h1>Advantages</h1> <div class="coords">',
      poststring: '<span class="more">Mouse over the card for more info</span> </div> </div> </div>'
    };
  },
  components: {
    BackgroundVideo: BackgroundVideo,
    industryInstructions: industryInstructions,
    industryHighlightText: industryHighlightText,
    mynFeatures: mynFeatures
  },
  methods: {
    demandPlanning: function demandPlanning() {
      _ElMessageBox.alert(" <div class=\"center\"> <div class=\"card black\">".concat(this.prestring, "<span>Group Name</span> <span>Joined January 2019</span> </div> <div class=\"coords\"> <span>Position/Role</span> <span>City, Country</span> </div> <div class=\"stats\"> <div> <div class=\"title\">Awards</div> <i class=\"fa fa-trophy\"></i> <div class=\"value\">2</div> </div> <div> <div class=\"title\">Matches</div> <i class=\"fa fa-gamepad\"></i> <div class=\"value\">27</div> </div> <div> <div class=\"title\">Pals</div> <i class=\"fa fa-group\"></i> <div class=\"value\">123</div> </div> <div> <div class=\"title\">Coffee</div> <i class=\"fa fa-coffee\"></i> <div class=\"value infinity\">\u221E</div> </div> </div> </div> </div> <div class=\"general\"> <h1>Demand Planning</h1> <p>Using historical data, gain deeper insight into your supply chains performance. Use AI insights to predict future demand.</p> ").concat(this.poststring), {
        dangerouslyUseHTMLString: true
      });
    },
    equipment: function equipment() {
      _ElMessageBox.alert(" <div class=\"center\"> <div class=\"card purple\">".concat(this.prestring, "<span>Group Name</span> <span>Joined January 2019</span> </div> <div class=\"coords\"> <span>Position/Role</span> <span>City, Country</span> </div> <div class=\"stats\"> <div> <div class=\"title\">Awards</div> <i class=\"fa fa-trophy\"></i> <div class=\"value\">2</div> </div> <div> <div class=\"title\">Matches</div> <i class=\"fa fa-gamepad\"></i> <div class=\"value\">27</div> </div> <div> <div class=\"title\">Pals</div> <i class=\"fa fa-group\"></i> <div class=\"value\">123</div> </div> <div> <div class=\"title\">Coffee</div> <i class=\"fa fa-coffee\"></i> <div class=\"value infinity\">\u221E</div> </div> </div> </div> </div> <div class=\"general\"> <h1>Tool Storage</h1> <p>Know which pieces of equipment are on site and easily check out tooling to staff to minimise loss.</p> ").concat(this.poststring), {
        dangerouslyUseHTMLString: true
      });
    },
    truck: function truck() {
      _ElMessageBox.alert("<div class=\"center\"> <div class=\"card blue\">".concat(this.prestring, "<span>Group Name</span> <span>Joined January 2019</span> </div> <div class=\"coords\"> <span>Position/Role</span> <span>City, Country</span> </div> <div class=\"stats\"> <div> <div class=\"title\">Awards</div> <i class=\"fa fa-trophy\"></i> <div class=\"value\">2</div> </div> <div> <div class=\"title\">Matches</div> <i class=\"fa fa-gamepad\"></i> <div class=\"value\">27</div> </div> <div> <div class=\"title\">Pals</div> <i class=\"fa fa-group\"></i> <div class=\"value\">123</div> </div> <div> <div class=\"title\">Coffee</div> <i class=\"fa fa-coffee\"></i> <div class=\"value infinity\">\u221E</div> </div> </div> </div> </div> <div class=\"general\"> <h1>Transit Tracking</h1> <p>Gain real-time visibility into the current location of your fleet, and understand historical performance better through an increase in datapoints collected.</p> ").concat(this.poststring), {
        dangerouslyUseHTMLString: true
      });
    },
    storage: function storage() {
      _ElMessageBox.alert("<div class=\"center\"> <div class=\"card\">".concat(this.prestring, "<span>Group Name</span> <span>Joined January 2019</span> </div> <div class=\"coords\"> <span>Position/Role</span> <span>City, Country</span> </div> <div class=\"stats\"> <div> <div class=\"title\">Awards</div> <i class=\"fa fa-trophy\"></i> <div class=\"value\">2</div> </div> <div> <div class=\"title\">Matches</div> <i class=\"fa fa-gamepad\"></i> <div class=\"value\">27</div> </div> <div> <div class=\"title\">Pals</div> <i class=\"fa fa-group\"></i> <div class=\"value\">123</div> </div> <div> <div class=\"title\">Coffee</div> <i class=\"fa fa-coffee\"></i> <div class=\"value infinity\">\u221E</div> </div> </div> </div> </div> <div class=\"general\"> <h1>Temporary Storage</h1> <p>Track movements of bulk goods as they move from container to container.</p> ").concat(this.poststring), {
        dangerouslyUseHTMLString: true
      });
    },
    office: function office() {
      _ElMessageBox.alert('<div class="center"><div class="card green"><div class="additional"><div class="user-card"> <small class="stats" style:"color:white; font-size:0.25rem> Oil</small></div><div class="more-info"><h1>Advantages</h1><div class="coords"> <span>&bull;Single Source Of Truth<br>&bull;Low-Data Mode<br>&bull;Ad-Hoc quality checking </span></div></div></div><div class="general"><h1>Real-time Access</h1><p> Whether on the field or at HQ, use Myneral to view details about performance and proactivley identify faults. Myneral ensures that there is a single source of truth for your business to operate from.</span></div></div></div>', {
        dangerouslyUseHTMLString: true
      });
    },
    api: function api(event) {
      console.log('Click on API Received', event.target.tagName);
    }
  }
};